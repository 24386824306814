<template>
  <div class="mention-tracking-report">
    <!-- In case we're showing a preview -->
    <div
      v-if="!isAuthenticated"
      class="mb-8"
    >
      <!-- Show the report logo, if any -->
      <v-img
        v-if="overview.model.logo"
        :src="reportLogo"
        height="100"
        contain
      />

      <!-- Show the report name -->
      <div class="text-h6 font-weight-bold text-center primary--text mt-3">
        {{ overview.model.name }}
      </div>

      <!-- Show the date range -->
      <div class="text-center text-subtitle-2 mt-1">
        {{ dayjs(overview.model.start_date).format("ll") }} - {{ dayjs(overview.model.end_date).format("ll") }}
      </div>
    </div>

    <!-- The header buttons -->
    <div class="d-flex justify-space-between mb-8">
      <!-- Show the go back button -->
      <v-btn
        v-if="isAuthenticated"
        text
        color="primary"
        @click="$router.push({ name: 'MentionTrackingIndex' })"
      >
        <v-icon left>
          arrow_back
        </v-icon>

        Back
      </v-btn>
      <!-- Don't show anything in the left -->
      <span v-else></span>

      <div>
        <!-- Show the menu here -->
        <v-menu
          v-if="isAuthenticated"
          transition="slide-y-transition"
          offset-y
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <!-- Show the menu options -->
          <v-list width="160" dense>
            <!-- Show the option to trigger share -->
            <v-list-item
              v-if="overview.canUserWrite"
              @click="shouldShowShareDialog = true"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Share
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Show the option to trigger export as PDF -->
            <v-list-item
              @click="triggerDownload('pdf')"
              :disabled="isMakingDownloadRequest.pdf"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Download PDF
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Show the option to trigger export as XLSX -->
            <v-list-item
              @click="triggerDownload('xlsx')"
              :disabled="isMakingDownloadRequest.xlsx"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Download XLSX
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div class="d-flex justify-space-between mb-4">
      <!-- Show the report name -->
      <div
        v-if="isAuthenticated"
        class="text-h6 font-weight-bold mt-3"
      >
        {{ overview.model.name }}
      </div>

      <!-- Show a switch button for sponsored filter -->
      <div class="d-flex align-center">
        <span class="mr-4 text-subtitle-2">
          Only Sponsored Posts?
        </span>

        <v-switch
          :input-value="overview.model.only_sponsored_posts || overview.onlySponsored"
          :readonly="overview.model.only_sponsored_posts"
          @change="toggleSponsoredPostsFilter"
          class="ma-0"
          color="primary"
          hide-details
          inset
        ></v-switch>
      </div>
    </div>

    <!-- Show the report brief here -->
    <div class="d-flex flex-wrap justify-center justify-md-space-between">
      <div class="d-flex align-center px-4 px-md-0">
        <!-- For each platform in this model, show their logo image -->
        <div class="d-flex">
          <v-img
            v-for="(platform, index) in overview.model.platforms"
            :key="'platform-logo-' + index"
            :src="`/img/socials/${platform}.svg`"
            width="32"
            height="32"
            max-width="32"
            class="mr-3"
          />
        </div>

        <!-- Show the date range value -->
        <div class="text-subtitle-2 font-weight-bold my-4">
          {{ dayjs(overview.model.start_date).format("ll") }} - {{ dayjs(overview.model.end_date).format("ll") }}
        </div>
      </div>

      <div class="d-flex">
        <!-- Show the usernames if available -->
        <div v-if="overview.model.usernames && overview.model.usernames.length">
          <!-- Show an overline text -->
          <div class="text-overline text-center mb-2">
            Usernames
          </div>

          <!-- Foreach username, show a chip with @ at the start -->
          <v-chip
            v-for="(username, index) in overview.model.usernames"
            :key="'username-' + index"
            color="primary"
            class="mx-1"
            small
          >
            @{{ username }}
          </v-chip>
        </div>

        <!-- Show the hashtags if available -->
        <div v-if="overview.model.hashtags && overview.model.hashtags.length" class="ml-3">
          <!-- Show an overline text -->
          <div class="text-overline text-center mb-2">
            Hashtags
          </div>

          <!-- Foreach hashtag, show a chip with # at the start -->
          <v-chip
            v-for="(hashtag, index) in overview.model.hashtags"
            :key="'hashtag-' + index"
            color="primary"
            class="mx-1"
            small
          >
            #{{ hashtag }}
          </v-chip>
        </div>

        <!-- Show the keywords if available -->
        <div v-if="overview.model.keywords && overview.model.keywords.length" class="ml-3">
          <!-- Show an overline text -->
          <div class="text-overline text-center mb-2">
            Keywords
          </div>

          <!-- Foreach keyword, show a chip -->
          <v-chip
            v-for="(keyword, index) in overview.model.keywords"
            :key="'keyword-' + index"
            color="primary"
            class="mb-2"
            small
          >
            {{ keyword }}
          </v-chip>
        </div>
      </div>
    </div>

    <!-- Show a divider -->
    <v-divider class="my-8" />

    <!-- If there are no posts -->
    <template v-if="response.posts.data.length === 0 && isRequestingSearch.posts === false">
      <div>
        <div class="text-center">
          There are no posts available for this report.
        </div>

        <!-- Show a button to reload the report -->
        <div
          v-if="didChangeFilters"
          class="mt-6 d-flex justify-center"
        >
          <v-btn
            depressed
            color="primary"
            @click="$emit('reload')"
          >
            <v-icon left>refresh</v-icon>

            Reset Filters
          </v-btn>
        </div>
      </div>
    </template>

    <!-- If there are posts available -->
    <template v-else>
      <!-- Show the stats values -->
      <overview
        module="mentionTracking"
        :categories="overview.categories"
        :daywise="overview.daywise"
        :overview="overview"
        show-annotations
        show-influencers
      />

      <!-- Show a divider -->
      <v-divider class="my-8" />

      <!-- Show the categories table -->
      <v-data-table
        :headers="categoriesTableHeaders"
        :items="overview.categories"
        hide-default-footer
        disable-pagination
        class="box-shadow-soft"
        :mobile-breakpoint="100"
      >
        <!-- For accounts, format it with number -->
        <template v-slot:item.accounts="{ item }">
          <span :title="item.accounts">
            {{ nFormatter(item.accounts) }}
          </span>
        </template>

        <!-- For followers, format it with number -->
        <template v-slot:item.followers="{ item }">
          <span :title="item.followers">
            {{ nFormatter(item.followers) }}
          </span>
        </template>

        <!-- For posts, format it with number -->
        <template v-slot:item.posts="{ item }">
          <span :title="item.posts">
            {{ nFormatter(item.posts) }}
          </span>
        </template>

        <!-- For likes, format it with number -->
        <template v-slot:item.likes="{ item }">
          <span :title="item.likes">
            {{ nFormatter(item.likes) }}
          </span>
        </template>

        <!-- For dislikes, format it with number -->
        <template v-slot:item.dislikes="{ item }">
          <span :title="item.dislikes">
            {{ nFormatter(item.dislikes) }}
          </span>
        </template>

        <!-- For views, format it with number -->
        <template v-slot:item.views="{ item }">
          <span :title="item.views">
            {{ nFormatter(item.views) }}
          </span>
        </template>

        <!-- For comments, format it with number -->
        <template v-slot:item.comments="{ item }">
          <span :title="item.comments">
            {{ nFormatter(item.comments) }}
          </span>
        </template>

        <!-- For shares, format it with number -->
        <template v-slot:item.shares="{ item }">
          <span :title="item.shares">
            {{ nFormatter(item.shares) }}
          </span>
        </template>

        <!-- For saves, format it with number -->
        <template v-slot:item.saves="{ item }">
          <span :title="item.saves">
            {{ nFormatter(item.saves) }}
          </span>
        </template>

        <!-- For engagement rate, show it as percentage -->
        <template v-slot:item.engagement_rate="{ item }">
          {{ (item.engagement_rate * 100).toFixed(2) + '%' }}
        </template>

        <!-- For audience credibility, show it as a percentage -->
        <template v-slot:item.average_credibility="{ item }">
          {{ (item.average_credibility * 100).toFixed(2) + '%' }}
        </template>
      </v-data-table>

      <!-- Show a divider -->
      <v-divider class="my-8" />

      <!-- Show the influencers table -->
      <div>
        <!-- Show the title -->
        <div class="text-h6 font-weight-bold mb-4">
          Influencers
        </div>

        <!-- Show the sort and search options -->
        <div class="d-flex justify-space-between">
          <!-- Show the sort option -->
          <div class="contain-select-width">
            <v-select
              v-model="sortBy"
              :items="sortOptions"
              label="Sort By"
              color="primary"
              hide-details
              outlined
              dense
              @change="didChangeFilters = true"
            ></v-select>
          </div>

          <div class="contain-select-width contain-select-width--2x">
            <v-select
              v-model="followersFilterBy"
              :items="followersFilterOptions"
              label="Followers' Range"
              color="primary"
              hide-details
              outlined
              dense
              @change="didChangeFilters = true"
            ></v-select>
          </div>
        </div>

        <!-- Show the influencers table -->
        <v-data-table
          :page="pagination.influencers.page"
          :headers="influencersTableHeaders"
          :items="influencersTableItems"
          :loading="isRequestingSearch.influencers"
          class="box-shadow-soft mt-6 influencers-data-table"
          :server-items-length="response.influencers.total"
          :items-per-page="pagination.influencers.perPage"
          @update:page="(_) => { pagination.influencers.page = _; searchInfluencers(); }"
          @update:items-per-page="(_) => { pagination.influencers.perPage = _; searchInfluencers() }"
          :mobile-breakpoint="100"
        >
          <!-- Modify the platform column -->
          <template v-slot:item.platform="{ item }">
            <div>
              <v-img
                :src="`/img/socials/${item.platform}.svg`"
                max-width="32"
                height="32"
              ></v-img>
            </div>
          </template>

          <!-- Modify the influencer column -->
          <template v-slot:item.username="{ item }">
            <profile-chip
              :platform="item.platform"
              :data="item.picture ? item : { picture: null, username: item.username, fullname: item.username }"
            />
          </template>

          <!-- Modify the posts count column -->
          <template v-slot:item.posts_count="{ item }">
            <span :title="item.posts_count">
              {{ nFormatter(item.posts_count) }}
            </span>
          </template>

          <!-- Modify the total followers column -->
          <template v-slot:item.followers="{ item }">
            <span
              v-if="item.followers"
              :title="item.followers"
            >
              {{ nFormatter(item.followers) }}
            </span>
            <span v-else>
              -
            </span>
          </template>

          <!-- Modify the total likes column -->
          <template v-slot:item.total_likes="{ item }">
            <span :title="item.total_likes">
              {{ nFormatter(item.total_likes) }}
            </span>
          </template>

          <!-- Modify the total dislikes column -->
          <template v-slot:item.total_dislikes="{ item }">
            <span :title="item.total_dislikes">
              {{ nFormatter(item.total_dislikes) }}
            </span>
          </template>

          <!-- Modify the total comments column -->
          <template v-slot:item.total_comments="{ item }">
            <span :title="item.total_comments">
              {{ nFormatter(item.total_comments) }}
            </span>
          </template>

          <!-- Modify the total views column -->
          <template v-slot:item.total_views="{ item }">
            <span :title="item.total_views">
              {{ nFormatter(item.total_views) }}
            </span>
          </template>

          <!-- Modify the total shares column -->
          <template v-slot:item.total_shares="{ item }">
            <span :title="item.total_shares">
              {{ nFormatter(item.total_shares) }}
            </span>
          </template>

          <!-- Modify the total saves column -->
          <template v-slot:item.total_saves="{ item }">
            <span :title="item.total_saves">
              {{ nFormatter(item.total_saves) }}
            </span>
          </template>

          <!-- Modify the average engagement column -->
          <template v-slot:item.average_engagement="{ item }">
            <span :title="item.average_engagement * 100">
              {{ (item.average_engagement * 100).toFixed(2) }}%
            </span>
          </template>

          <!-- Modify the audience credibility column -->
          <template v-slot:item.audience_credibility="{ item }">
            <span
              v-if=item.audience_credibility
              :title="item.audience_credibility * 100"
            >
              {{ (item.audience_credibility * 100).toFixed(2) }}%
            </span>
            <span v-else>
              -
            </span>
          </template>
        </v-data-table>
      </div>

      <!-- Show a divider -->
      <v-divider class="my-8" />

      <!-- Show the posts grid -->
      <div>
        <!-- Show the title -->
        <div class="text-h6 font-weight-bold mb-4">
          Posts
        </div>

        <!-- Show the sort and search options -->
        <div class="d-flex justify-space-between">
          <!-- Show the sort option -->
          <div class="contain-select-width">
            <v-select
              v-model="sortBy"
              :items="sortOptions"
              label="Sort By"
              color="primary"
              hide-details
              outlined
              dense
              @change="didChangeFilters = true"
            ></v-select>
          </div>

          <div class="contain-select-width contain-select-width--2x">
            <v-select
              v-model="followersFilterBy"
              :items="followersFilterOptions"
              label="Followers' Range"
              color="primary"
              hide-details
              outlined
              dense
              @change="didChangeFilters = true"
            ></v-select>
          </div>
        </div>

        <!-- Show the posts grid -->
        <v-row
          v-if="response.posts.data.length"
          class="mt-6"
        >
          <v-col
            v-for="item in response.posts.data"
            :key="'post-' + item.id"
            class="px-2"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <!-- Use a common posts component -->
            <post :data="item" />
          </v-col>
        </v-row>

        <div
          v-else
          class="text-center mt-8 font-weight-bold"
        >
          {{ isRequestingSearch.posts ? 'Loading ...' : 'No posts found, please try changing the filters.' }}
        </div>

        <div
          id="intersect-detector"
          ref="intersectDetector"
          v-intersect="handleIntersect"
        ></div>
      </div>
    </template>

    <!-- Show a dialog for managing access -->
    <v-dialog
      v-model="shouldShowShareDialog"
      :persistent="isMakingShareRequest"
      max-width="680"
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          Share this report
        </v-card-title>

        <v-card-text class="pt-6">
          <!-- Show the inputs for form values -->
          <v-row>
            <!-- First show the options about team access -->
            <v-col
              v-if="isWhitelisted"
              cols="12"
              md="6"
            >
              <v-select
                v-model="form.teamBehaviour"
                :items="teamBehaviourOptions"
                :label="form.teamBehaviour === 'hidden' ? 'For your teammates' : 'Let your teammates'"
                :disabled="isMakingTeamBehaviourRequest"
                :loading="isMakingTeamBehaviourRequest"
                @change="handleTeamBehaviourChange"
                color="primary"
                hide-details
                outlined
                dense
              ></v-select>
            </v-col>

            <!-- Then show the options about public access -->
            <v-col
              cols="12"
              :md="isWhitelisted ? 6 : 12"
            >
              <v-select
                v-model="form.isShareable"
                :items="isShareableOptions"
                label="Who can see this report?"
                :disabled="isMakingShareableRequest"
                :loading="isMakingShareableRequest"
                @change="handleShareableRequest"
                color="primary"
                hide-details
                outlined
                dense
              ></v-select>
            </v-col>

            <!-- If we did allow anyone to see it -->
            <v-col
              v-if="form.isShareable"
              cols="12"
            >
              <v-text-field
                :value="shareableURL"
                label="Click to copy shareable URL"
                @click="copyLinkToClipboard"
                color="primary"
                hide-details
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Show a divider -->
          <v-divider class="my-6" />

          <div>
            You can share this report with people who are not your team members.
          </div>

          <div class="pt-2">
            You may also invite your selected team members to grant them write access and keep your other team members on read-only permission.
          </div>

          <!-- Show the input for inviting new members -->
          <v-text-field
            v-model.trim="form.memberEmail"
            label="Add a new member"
            placeholder="Enter Email Address"
            color="primary"
            class="mt-4"
            append-icon="send"
            @click:append="handleInviteCreate"
            @keypress.enter="handleInviteCreate"
            :loading="isMakingCreateMemberRequest"
            :disabled="isMakingCreateMemberRequest"
            hide-details
            outlined
            dense
          ></v-text-field>

          <!-- Show current report members -->
          <div class="pt-3">
            <div
              v-for="item in overview.members"
              :key="'member-' + item.id"
              class="d-flex align-center mt-3"
            >
              <!-- Show the avatar first -->
              <user-avatar :name="item.user.name" />

              <!-- Show the name and email -->
              <div class="px-3 flex-grow-1">
                <div>
                  {{ item.user.name }}
                </div>

                <div>
                  {{ item.user.email }}
                </div>
              </div>

              <!-- Show action buttons -->
              <v-menu
                :disabled="isMakingUpdateMemberRequest || isMakingDeleteMemberRequest"
                transition="slide-y-transition"
                :close-on-content-click="false"
                offset-y
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <!-- Show the menu options -->
                <v-list width="200" dense>
                  <!-- Show the update button -->
                  <v-list-item
                    @click="handleInviteUpdate(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        Grant Write Access
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :value="item.can_write"
                        hide-details
                      />
                    </v-list-item-action>
                  </v-list-item>

                  <!-- Show the delete button -->
                  <v-list-item
                    @click="handleInviteDelete(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        Remove Access
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"
import { email } from "vuelidate/lib/validators"

// Import children components
const Post = () => import(/* webpackChunkName: "crm-post" */ "@/components/crm/Post.vue")
const Overview = () => import(/* webpackChunkName: "crm-overview" */ "@/components/crm/Overview")
const UserAvatar = () => import(/* webpackChunkName: "user-avatar" */ "@/blocks/common/UserAvatar")
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")

// Import helper objects
import followersFilterOptions from "@/helpers/paid-collaborations/filterOptions.js"

// Subscription ID for messageEvents
const subscriptionId = Symbol("MentionTracking/Report")

// Export the SFC
export default {
  // Name of the component
  name: "MentionTrackingReport",

  // Accept incoming data from parent
  props: {
    overview: {
      type: Object,
      required: true
    }
  },

  // Register child components
  components: {
    Post,
    Overview,
    UserAvatar,
    StatusChip,
    ProfileChip
  },

  // Define local data variables
  data: () => ({
    // Whether or not the filters were changed
    didChangeFilters: false,

    // Followers filter values
    followersFilterBy: 0,
    followersFilterOptions: followersFilterOptions(),

    // Sort filter values
    sortBy: "most_liked",
    sortOptions: [
      {
        text: "Recent",
        value: "recent"
      },
      {
        text: "Oldest",
        value: "oldest"
      },
      {
        text: "Least Liked",
        value: "least_liked"
      },
      {
        text: "Most Liked",
        value: "most_liked"
      },
      {
        text: "Least Commented",
        value: "least_commented"
      },
      {
        text: "Most Commented",
        value: "most_commented"
      },
      {
        text: "Lowest Credibility",
        value: "lowest_credibility"
      },
      {
        text: "Highest Credibility",
        value: "highest_credibility"
      },
      {
        text: "Lowest Followers",
        value: "lowest_followers"
      },
      {
        text: "Highest Followers",
        value: "highest_followers"
      }
    ],
    sortMap: {
      recent: {
        sortBy: "posted_at",
        sortOrder: "desc"
      },

      oldest: {
        sortBy: "posted_at",
        sortOrder: "asc"
      },

      least_liked: {
        sortBy: "likes",
        sortOrder: "asc"
      },

      most_liked: {
        sortBy: "likes",
        sortOrder: "desc"
      },

      least_commented: {
        sortBy: "comments",
        sortOrder: "asc"
      },

      most_commented: {
        sortBy: "comments",
        sortOrder: "desc"
      },

      lowest_credibility: {
        sortBy: "audience_credibility",
        sortOrder: "asc"
      },

      highest_credibility: {
        sortBy: "audience_credibility",
        sortOrder: "desc"
      },

      lowest_followers: {
        sortBy: "followers",
        sortOrder: "asc"
      },

      highest_followers: {
        sortBy: "followers",
        sortOrder: "desc"
      }
    },

    isRequestingSearch: {
      posts: false,
      influencers: false
    },

    pagination: {
      posts: {
        page: 1,
        perPage: 8
      },
      influencers: {
        page: 1,
        perPage: 10
      }
    },
    response: {
      posts: {
        data: [],
        total: 0
      },
      influencers: {
        data: [],
        total: 0
      }
    },

    // Whether or not is making a download
    isMakingDownloadRequest: {
      pdf: false,
      xlsx: false
    },
    triggerId: {
      pdf: null,
      xlsx: null
    },
    downloadLoaders: {
      pdf: null,
      xlsx: null,
    },

    // Whether or not to show the shareable options dialog
    shouldShowShareDialog: false,
    // Whether or not is the app making a share related request
    isMakingShareRequest: false,
    // Whether or not is the teamBehaviour value changing
    isMakingTeamBehaviourRequest: false,
    // Whether or not is the isShareable value changing
    isMakingShareableRequest: false,
    // Whether or not is making a new member
    isMakingCreateMemberRequest: false,
    // Whether or not is updating a member
    isMakingUpdateMemberRequest: false,
    // Whether or not is deleting a member
    isMakingDeleteMemberRequest: false,

    form: {
      teamBehaviour: "hidden",
      isShareable: false,
      memberEmail: "",
    },

    teamBehaviourOptions: [
      {
        text: "Hide from them",
        value: "hidden"
      },
      {
        text: "See this report",
        value: "read-only"
      },
      {
        text: "Edit this report",
        value: "read-write"
      }
    ],

    isShareableOptions: [
      {
        text: "Only authorized people",
        value: false
      },
      {
        text: "Let anyone with a link to see it",
        value: true
      },
    ]
  }),

  // Define local readonly variables
  computed: {
    /**
     * Get the table headers array for categories
     *
     * @returns {Array}
     */
    categoriesTableHeaders() {
      return [
        {
          text: "Category",
          value: "title",
          sortable: true,
          sort: (a, b) => {
            // Get the indexes for both of these
            const i1 = this.overview.categories.find((search) => search.title === a).index
            const i2 = this.overview.categories.find((search) => search.title === b).index

            // Compare and return
            return i1 - i2
          }
        },
        {
          text: "Accounts",
          value: "accounts",
          sortable: true
        },
        {
          text: "Followers",
          value: "followers",
          sortable: true
        },
        {
          text: "Posts",
          value: "posts",
          sortable: true
        },
        {
          text: "Likes",
          value: "likes",
          sortable: true,
          condition: this.overview.categories.some((item) => item.likes > 0)
        },
        {
          text: "Dislikes",
          value: "dislikes",
          sortable: true,
          condition: this.overview.categories.some((item) => item.dislikes > 0)
        },
        {
          text: "Views",
          value: "views",
          sortable: true,
          condition: this.overview.categories.some((item) => item.views > 0)
        },
        {
          text: "Comments",
          value: "comments",
          sortable: true,
          condition: this.overview.categories.some((item) => item.comments > 0)
        },
        {
          text: "Shares",
          value: "shares",
          sortable: true,
          condition: this.overview.categories.some((item) => item.shares > 0)
        },
        {
          text: "Saves",
          value: "Saves",
          sortable: true,
          condition: this.overview.categories.some((item) => item.saves > 0)
        },
        {
          text: "Eng Rate",
          value: "engagement_rate",
          sortable: true,
          condition: this.overview.categories.some((item) => item.engagement_rate > 0)
        }
      ].filter((item) => item.condition !== false)
    },

    /**
     * Get the table headers array for influencers
     *
     * @returns {Array}
     */
    influencersTableHeaders() {
      return [
        {
          text: "",
          value: "platform",
          sortable: false,
        },
        {
          text: "Influencer",
          value: "username",
          sortable: false
        },
        {
          text: "Posts",
          value: "posts_count",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.posts_count > 0)
        },
        {
          text: "Followers",
          value: "followers",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.followers > 0)
        },
        {
          text: "Likes",
          value: "total_likes",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.total_likes > 0)
        },
        {
          text: "Dislikes",
          value: "total_dislikes",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.total_dislikes > 0)
        },
        {
          text: "Views",
          value: "total_views",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.total_views > 0)
        },
        {
          text: "Comments",
          value: "total_comments",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.total_comments > 0)
        },
        {
          text: "Shares",
          value: "total_shares",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.total_shares > 0)
        },
        {
          text: "Saves",
          value: "total_saves",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.total_saves > 0)
        },
        {
          text: "Avg Engagement",
          value: "average_engagement",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.average_engagement > 0)
        },
        {
          text: "Audience Credibility",
          value: "audience_credibility",
          sortable: false,
          condition: this.influencersTableItems.some((item) => item.audience_credibility > 0)
        },
      ].filter((item) => item.condition !== false)
    },

    /**
     * Get the table items array for influencers
     *
     * @returns {Array}
     */
    influencersTableItems() {
      return this.response.influencers.data.map((item) => ({
        ...item,
        average_engagement: (((item.likes || 0) + (item.comments || 0) + (item.shares || 0) + (item.saves || 0)) / item.posts_count) / item.followers
      }))
    },

    /**
     * Get the shareable URL in string
     *
     * @returns {String}
     */
    shareableURL() {
      return `${window.location.origin}${this.$router.resolve({ name: 'MentionTrackingView' }).href}`
    },

    /**
     * Whether or not the user is authenticated
     *
     * @returns {Boolean}
     */
     isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },

    /**
     * Get the logged in user's data object
     *
     * @returns {Object}
     */
    profileData() {
      return this.$store.getters["auth/profile"]
    },

    /**
     * Whether or not the currently authenticated user is whitelisted or not
     *
     * @returns {Boolean}
     */
    isWhitelisted() {
      return this.profileData ? this.profileData.is_whitelisted : false
    },

    /**
     * Get the report logo
     *
     * @returns {String}
     */
    reportLogo() {
      return this.overview.model.logo ? `${process.env.VUE_APP_STORAGE_BASE}/${this.overview.model.logo}` : null
    }
  },

  // Watch for any changes
  watch: {
    // If the sort filter changes
    sortBy() {
      // Reset the pagination
      this.response.posts.data = []
      this.pagination.posts.page = 1
      this.pagination.influencers.page = 1

      // Call the search function
      this.searchPosts()
      this.searchInfluencers()
    },

    // If the followers filter changes
    followersFilterBy() {
      // Reset the pagination
      this.response.posts.data = []
      this.pagination.posts.page = 1
      this.pagination.influencers.page = 1

      // Call the search function
      this.searchPosts()
      this.searchInfluencers()
    }
  },

  // Define local method functions
  methods: {
    /**
     * Whether or not the user has scrolled past the intersection limit
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
    handleIntersect(entries, observer, isIntersecting) {
      // Stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If a request is being made, don't continue
      if (this.isRequestingSearch.posts) return false

      // If there's no data possible, don't continue
      if (this.response.posts.data.length >= this.response.total) return false

      // Otherwise, call the search function
      this.pagination.posts.page++
      this.searchPosts()
    },

    /**
     * Toggle the sponsored posts filter
     *
     * @returns {void}
     */
    toggleSponsoredPostsFilter() {
      this.$emit("reload", !this.overview.onlySponsored)
    },

    /**
     * Fetch posts to be shown in grid
     *
     * @returns {void}
     */
    async searchPosts() {
      // Show a loader
      const loaderId = Symbol("MentionTracking/Report/searchPosts")
      this.$store.dispatch("loaders/add", loaderId)
      this.isRequestingSearch.posts = true

      // Try making a network request
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            onlySponsored: this.overview.onlySponsored,

            page: this.pagination.posts.page,
            per_page: this.pagination.posts.perPage,
            minimumFollowers: this.followersFilterOptions[this.followersFilterBy].min,
            maximumFollowers: this.followersFilterOptions[this.followersFilterBy].max === Infinity ? null : this.followersFilterOptions[this.followersFilterBy].max,
            ...this.sortMap[this.sortBy]
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/mention-tracking/${this.overview.model.id}/posts?${queryParams}`)

        // Update the response object
        this.response.posts = {
          total: response.data.total,
          data: [
            ...this.response.posts.data,
            ...response.data.data
          ]
        }
      }
      // Catch an error
      catch (error) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch posts!" })

        // Log it
        logger({ type: "MentionTracking/Report/searchPosts", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isRequestingSearch.posts = false
      }
    },

    /**
     * Fetch influencers to be shown in table
     *
     * @returns {void}
     */
    async searchInfluencers() {
      // Show a loader
      const loaderId = Symbol("MentionTracking/Report/searchInfluencers")
      this.$store.dispatch("loaders/add", loaderId)
      this.isRequestingSearch.influencers = true

      // Try making a network request
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            onlySponsored: this.overview.onlySponsored,

            page: this.pagination.influencers.page,
            per_page: this.pagination.influencers.perPage,
            minimumFollowers: this.followersFilterOptions[this.followersFilterBy].min,
            maximumFollowers: this.followersFilterOptions[this.followersFilterBy].max === Infinity ? null : this.followersFilterOptions[this.followersFilterBy].max,
            ...this.sortMap[this.sortBy]
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/mention-tracking/${this.overview.model.id}/influencers?${queryParams}`)

        // Update the response object
        this.response.influencers = {
          total: response.data.total,
          data: response.data.data
        }
      }
      // Catch an error
      catch (error) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch influencers!" })

        // Log it
        logger({ type: "MentionTracking/Report/searchInfluencers", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isRequestingSearch.influencers = false
      }
    },

    /**
     * Trigger and handle the request to change team-behaviour value
     *
     * @returns {void}
     */
     async handleTeamBehaviourChange() {
      // Set the loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingTeamBehaviourRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making the network request
      try {
        await axios({
          url: `/api/mention-tracking/${this.overview.model.id}/team-behaviour`,
          method: "PUT",
          data: {
            team_behaviour: this.form.teamBehaviour
          }
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Report updated!" })

        // Update the local store value
        this.$store.dispatch("mentionTracking/updateModel", { ...this.overview.model, team_behaviour: this.form.teamBehaviour })
      }
      // Catch the error
      catch (error) {
        // Log the error
        logger({ type: "MentionTracking/TeamBehaviour Update Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nevertheless
      finally {
        // Remove the loaders
        this.isMakingShareRequest = false
        this.isMakingTeamBehaviourRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Trigger and handle the is_shareable value change
     *
     * @returns {void}
     */
    async handleShareableRequest() {
      // Set the loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingShareableRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making the network request
      try {
        await axios({
          url: `/api/mention-tracking/${this.overview.model.id}/is-shareable`,
          method: "PUT",
          data: {
            is_shareable: this.form.isShareable
          }
        })

        // If succeeded, show a message
        this.$store.dispatch("toasts/add", { text: "Report updated!" })

        // Update the local store value
        this.$store.dispatch("mentionTracking/updateModel", { ...this.overview.model, is_shareable: this.form.isShareable })
      }
      // Catch the error
      catch (error) {
        // Log the error
        logger({ type: "MentionTracking/IsShareable Update Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      }
      // Nevertheless
      finally {
        // Remove the loaders
        this.isMakingShareRequest = false
        this.isMakingShareableRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Validate and make a network request for members
     *
     * @returns {void}
     */
    async handleInviteCreate() {
      // If we're already making a request
      if (this.isMakingCreateMemberRequest) {
        // Stop further execution
        return
      }

      // If the email input value isn't valid
      if (this.form.memberEmail.length === 0 || email(this.form.memberEmail) === false) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "Invalid email address" })

        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingCreateMemberRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making a request
      try {
        // Use helper function
        const response = await axios({
          url: `/api/mention-tracking/${this.overview.model.id}/members`,
          data: { email: this.form.memberEmail.trim().toLowerCase() },
          method: "POST"
        })

        // If we have the response, update the list
        this.$store.dispatch("mentionTracking/updateMember", { id: this.overview.model.id, member: response.data })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Added as a report member!" })

        // Reset the form
        this.form.memberEmail = ""
      }
      // Catch any error
      catch (error) {
        // Log the response
        logger({ type: "MentionTracking/CreateMember Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nonetheless
      finally {
        this.isMakingShareRequest = false
        this.isMakingCreateMemberRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Toggle the can write access value
     *
     * @param {Object} member
     * @returns {void}
     */
    async handleInviteUpdate(member) {
      // If we're already making a request
      if (this.isMakingUpdateMemberRequest) {
        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingUpdateMemberRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making a request
      try {
        // Use helper function
        const response = await axios({
          url: `/api/mention-tracking/${this.overview.model.id}/members/${member.id}`,
          data: { can_write: !member.can_write },
          method: "PUT"
        })

        // If we have the response, update the list
        this.$store.dispatch("mentionTracking/updateMember", { id: this.overview.model.id, member: response.data })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Updated member permission!" })
      }
      // Catch any error
      catch (error) {
        // Log the response
        logger({ type: "MentionTracking/UpdateMember Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nonetheless
      finally {
        this.isMakingShareRequest = false
        this.isMakingUpdateMemberRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Remove the member from the table
     *
     * @param {Object} member
     * @returns {void}
     */
    async handleInviteDelete(member) {
      // If we're already making a request
      if (this.isMakingDeleteMemberRequest) {
        // Stop further execution
        return
      }

      // Otherwise, show a loader
      const loaderId = Symbol()
      this.isMakingShareRequest = true
      this.isMakingDeleteMemberRequest = true
      this.$store.dispatch("loaders/add", loaderId)

      // Try making a request
      try {
        // Use helper function
        await axios({
          url: `/api/mention-tracking/${this.overview.model.id}/members/${member.id}`,
          method: "DELETE"
        })

        // If we have the response, update the list
        this.$store.dispatch("mentionTracking/removeMember", { id: this.overview.model.id, member: member })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Removed member from report!" })
      }
      // Catch any error
      catch (error) {
        // Log the response
        logger({ type: "MentionTracking/RemoveMember Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred" })
      }
      // Nonetheless
      finally {
        this.isMakingShareRequest = false
        this.isMakingDeleteMemberRequest = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Copy the shareable link to the clipboard
     *
     * @returns {void}
     */
    copyLinkToClipboard() {
      // Copy the link value
      navigator.clipboard.writeText(this.shareableURL)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },

    /**
     * Handle the request to export this report as PDF
     *
     * @param {String} type
     * @returns {void}
     */
    async triggerDownload(type) {
      // Show a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingDownloadRequest[type] = true

      // Make a network request
      try {
        // Set a triggerId
        this.triggerId[type] = String(Date.now())

        // Use helper function
        await axios({
          url: `/api/mention-tracking/${this.overview.model.id}/download/${type}?onlySponsored=${this.overview.onlySponsored}`,
          method: "POST",
          data: {
            clientId: getClientID(),
            triggerId: this.triggerId[type]
          }
        })
      }
      // Catch the error
      catch (error) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "An error occurred, please try later." })

        // Hide the loader
        this.isMakingDownloadRequest[type] = false
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Please wait while we generate your report..." })
      }
    },

    /**
     * Stop the download progress
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the clientId does not match
      if (getClientID() !== event.localData.clientId) {
        // Stop further execution
        return
      }

      // Switch through the cases
      switch (event.key) {
        case "generate-mention-tracking-pdf-started":
          // Check if the triggerId does not match
          if (this.triggerId.pdf !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a global loader
          this.downloadLoaders.pdf = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaders.pdf)

          break

        case "generate-mention-tracking-pdf-completed":
          // Check if the triggerId does not match
          if (this.triggerId.pdf !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.pdf)
          this.isMakingDownloadRequest.pdf = false

          break

        case "generate-mention-tracking-pdf-failed":
          // Check if the triggerId does not match
          if (this.triggerId.pdf !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.pdf)
          this.isMakingDownloadRequest.pdf = false

          break

        case "generate-mention-tracking-xlsx-started":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a global loader
          this.downloadLoaders.xlsx = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaders.xlsx)

          break

        case "generate-mention-tracking-xlsx-completed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break

        case "generate-mention-tracking-xlsx-failed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break
      }
    },
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // Set the default form values
    this.form.teamBehaviour = this.overview.model.team_behaviour
    this.form.isShareable = this.overview.model.is_shareable

    // Fetch the posts
    this.searchPosts()

    // Fetch the influencers
    this.searchInfluencers()

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "mention-tracking",
      type: "all",
      key: "all",
      validator: (event) => event.module === "mention-tracking" && event.localData.modelId === this.overview.model.id,
      callback: this.handleMessageEvent
    })
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  width 15em

  &--large
    width 20em
</style>

